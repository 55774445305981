<template>
  <div class="mainregisteremail">
    <div class="c-form">
      <div class="container-title">
        <h1 class="text-login">Cambio de Contraseña</h1>
      </div>
      <div style="display: flex; justify-content: center; align-items: center">
        <form class="form-password" name="form" id="form" v-on:submit.prevent="register()"
          style="display: flex; justify-content:center; align-items: center; flex-direction: column;">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"
              style="display: flex; justify-content:center; align-items: center;"><box-icon
                name='key'></box-icon></span>
            <input type="password" class="form-control" placeholder="Ingresa Tu Nueva Contraseña"
              aria-label="Contraseña" aria-describedby="basic-addon1" v-model="state.password">
          </div>
          <div class="input-errors-1" v-for="error of v$.password.$errors" :key="error.$uid">
            <div class="error-msg-1">
              {{ error.$message }}
            </div>
          </div>
          &nbsp;
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"
              style="display: flex; justify-content:center; align-items: center;"><box-icon
                name='key'></box-icon></span>
            <input type="password" class="form-control" placeholder="Confirma Tu Nueva Contraseña"
              aria-label="Contraseña" aria-describedby="basic-addon1" v-model="state.password2">
          </div>
          <div class="input-errors-1" v-for="error of v$.password2.$errors" :key="error.$uid">
            <div class="error-msg-1">
              {{ error.$message }}
            </div>
          </div>
          <input class="btn-register" type="submit" value="Cambiar Contraseña" title="Cambiar Contraseña" />
        </form>
      </div>

    </div>
  </div>
</template>
<script>
import { computed, reactive } from "vue";
import useValidate from "@vuelidate/core";
import { UserService } from "@/services";
import {
  required,
  minLength,
  maxLength,
  helpers,
  sameAs,
} from "@vuelidate/validators";

export default {
  setup() {
    const state = reactive({
      password: "",
      password2: "",
    });
    const rules = computed(() => {
      return {
        password: {
          required: helpers.withMessage("Ingrese una contraseña.", required),
          minLength: helpers.withMessage("Minimo 5 caracteres.", minLength(5)),
          maxLength: helpers.withMessage(
            "Maximo 10 caracteres.",
            maxLength(15)
          ),
        },
        password2: {
          sameAs: helpers.withMessage(
            "Las contraseñas no coinciden.",
            sameAs(state.password)
          ),
        },
      };
    });
    const v$ = useValidate(rules, state);
    return {
      state,
      v$,
    };
  },
  data() {
    return {
      token: null,
    };
  },
  mounted() {
    this.token = this.$route.params.token;
  },
  methods: {
    register() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log(this.state.password)
        const data = { token: this.token, password: this.state.password };
        UserService.resetPassword(data).then((response) => {
          if (response.data.success === true) {
            this.$router.push("/login");
          }
          this.$swal({
            position: "top-center",
            icon: "success",
            text: response.data.message,
            showConfirmButton: false,
            timer: 3000,
          });
        })
          .catch((error) => {
            this.$swal({
              position: "top-center",
              icon: "error",
              text: error.response.data.message,
              showConfirmButton: false,
              timer: 2500,
            });
          });
      }
    },
  },
};
</script>

<style land="scss" scoped>
@import "../styles/resetPassword.scss";
</style>
